/* * @Descripttion: 用印申请详情 * @version: 2.2.1 * @LastEditors: 邓亚鑫 * @version: 2.2.2 * @LastEditors: 邓亚鑫 *
@LastEditTime: 2022-03-25 15:05 */
<template>
  <div ref="parent">
    <a-modal
      centered
      :width="454"
      :title="modalTitle"
      :visible="visible"
      @cancel="closeEditModal"
      :getContainer="() => $refs.parent"
    >
      <section>
        <a-form ref="formRef" :rules="rules" :model="formModel" :label-col="labelCol">
          <div v-if="type === 'account' && step === 1">
            <a-form-item label="当前手机号">
              {{ accountType == 2 ? '无手机号' : mobile }}
              <a-button :disabled="captchaText !== '获取验证码'" class="get-captcha" @click="getCurrentCaptcha">{{
                captchaText
              }}</a-button>
            </a-form-item>
            <a-form-item label="验证码" name="nowCaptcha">
              <a-input :maxlength="6" style="width: 320px" placeholder="请输入验证码" @change="handledChange"
            /></a-form-item>
          </div>
          <div v-if="type === 'account' && step === 2">
            <a-form-item label="当前手机号">
              {{ accountType == 2 ? '无手机号' : mobile }}
            </a-form-item>
            <a-form-item label="新手机号" name="account">
              <a-input v-model:value="formModel.account" style="width: 320px" placeholder="请输入新手机号" />
            </a-form-item>
            <a-form-item label="验证码" name="captcha">
              <a-input :maxlength="6" style="width: 193px" placeholder="请输入验证码" @change="handledChange" />
              <a-button :disabled="captchaText !== '获取验证码'" class="get-captcha" @click="getCaptcha">{{
                captchaText
              }}</a-button>
            </a-form-item>
          </div>
          <div v-if="type === 'password'">
            <a-form-item label="原密码" name="oldPassword" v-if="type === 'password'">
              <a-input-password
                autoComplete="new-password"
                v-model:value="formModel.oldPassword"
                placeholder="请输入原密码"
                type="password"
                style="width: 320px;"
                :maxlength="16"
              />
              <!-- @change="formModel.oldPassword = formModel.oldPassword.replace(/\W+/g, '')" -->
            </a-form-item>
            <a-form-item label="新密码" name="newPassword">
              <a-input-password
                v-model:value="formModel.newPassword"
                placeholder="请输入新密码"
                type="password"
                :maxlength="16"
                style="width: 320px"
              />
              <!-- @change="formModel.newPassword = formModel.newPassword.replace(/\W+/g, '')" -->
              <div class="pwd-tips">密码必须是8-16位的英文字母、数字、字符组成，且至少含3种以上字符</div>
            </a-form-item>
            <a-form-item label="确认密码" name="againPassword">
              <a-input-password
                type="password"
                v-model:value="formModel.againPassword"
                placeholder="请再次输入新密码"
                :maxlength="16"
                style="width: 320px"
              />
              <!-- @change="formModel.againPassword = formModel.againPassword.replace(/\W+/g, '')" -->
            </a-form-item>
          </div>
          <div v-if="type === 'repassword'">
            <a-form-item label="设置密码" name="newPassword">
              <a-input-password
                v-model:value="formModel.newPassword"
                placeholder="请输入密码"
                type="password"
                style="width: 320px"
                :maxlength="16"
              />
              <div class="pwd-tips">密码必须是8-16位的英文字母、数字、字符组成，且至少含3种以上字符</div>
            </a-form-item>
          </div>
        </a-form>
        <!-- <a-button class="form-btn" :loading="loading" @click="modalSubmit">提交</a-button> -->
      </section>
      <template #footer>
        <a-button @click="cancel" class="MyCancel">取消</a-button>
        <a-button
          :loading="loading"
          @click="modalSubmit"
          style="color: #ffffff; background-color: #c3161c; border: none"
          >提交</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { checkCurrentPhone, editAccount, editAccountPassword, getAccountCaptcha, initUserPass } from '@/apis/user'
import { computed, reactive, ref, toRefs } from 'vue'
import { cmsNotice, ToCDB } from '@/utils/utils'
import { SET_USER_INFO } from '@/store/user/mutations-type'
import { useStore } from 'vuex'
import { notification } from 'ant-design-vue'

export default {
  name: 'edit-user-modal',
  props: {
    mobile: {
      type: String,
      required: true,
      default: ''
    },
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    type: {
      type: String,
      default: 'account'
    },
    accountType: {
      type: Number,
      default: 1
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      captchaText: '获取验证码',
      loading: false,
      timer: null
    })
    const modalTitle = computed(() =>
      props.type === 'account' ? '更换账号' : props.type === 'password' ? '修改登录密码' : '设置登录密码'
    )
    const step = ref(1)
    const formRef = ref()
    const formModel = reactive({
      nowCaptcha: undefined,
      oldPassword: undefined,
      newPassword: undefined,
      againPassword: undefined,
      account: undefined,
      captcha: undefined
    })
    const againValidator = (rule, value) => {
      if (!value) {
        return Promise.reject('请输入确认密码')
      }
      if (value !== formModel.newPassword) {
        return Promise.reject('确认密码应和新密码一致')
      }

      return Promise.resolve()
    }
    const rules = {
      oldPassword: [
        { required: true, message: '请输入原密码', trigger: 'blur' }
        // { min: 8, max: 16, message: '请输入8-16位密码', trigger: 'blur' }
      ],
      newPassword: [
        { required: true, message: '请输入新密码' },
        { min: 8, max: 16, message: '请输入8-16位密码', trigger: 'blur' }
      ],
      againPassword: [
        { required: true, message: '请再次输入新密码' },
        { min: 8, max: 16, message: '密码限制8-16位字符', trigger: 'blur' },
        { validator: againValidator, trigger: 'blur' }
      ],
      account: [
        {
          required: true,
          message: '请输入手机号'
        },
        {
          pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
          message: '手机号格式不正确'
        }
      ],
      captcha: [
        { required: true, message: '请输入验证码' },
        {
          pattern: /^[0-9]*$/,
          message: '验证码格式不正确'
        }
      ],
      nowCaptcha: [
        { required: true, message: '请输入验证码' },
        {
          pattern: /^[0-9]*$/,
          message: '验证码格式不正确'
        }
      ]
    }
    const getCurrentCaptcha = async () => {
      if (props.accountType == 2)
        return notification['error']({
          message: '提示',
          description: '该账户无手机号'
        })
      let num = 60
      state.captchaText = `${num--}s`
      state.timer = setInterval(() => {
        if (num === -1) {
          clearInterval(state.timer)
          state.timer = null
          state.captchaText = '获取验证码'
          state.captcha = '验证码失效，请重新发送'
          return
        }
        state.captchaText = `${num--}s`
      }, 1000)
      // console.log('props.mobie',props.mobie);
      const res = await getAccountCaptcha({ phone: props.mobile, code: 'MODIFY_MOBILE' })
      state.nowCaptcha = typeof res.data === 'number' ? res.data : true
    }
    const getCaptcha = async () => {
      // 获取验证码
      if (!formModel.account) {
        cmsNotice('error', '请输入手机号')
        return
      }
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(formModel.account)) {
        cmsNotice('error', '请输入正确的手机号')
        return
      }
      if (formModel.account === props.mobile.value) {
        cmsNotice('error', '修改账号不能和原来账号一样')
        state.loading = false
        return
      }
      let num = 60
      state.captchaText = `${num--}s`
      state.timer = setInterval(() => {
        if (num === -1) {
          clearInterval(state.timer)
          state.timer = null
          state.captchaText = '获取验证码'
          state.captcha = '验证码失效，请重新发送'
          return
        }
        state.captchaText = `${num--}s`
      }, 1000)
      const res = await getAccountCaptcha({ phone: formModel.account, code: 'MODIFY_MOBILE' })
      state.captcha = typeof res.data === 'number' ? res.data : true
    }
    const closeEditModal = () => {
      emit('update:visible', false)
      if (state.type === 'account') {
        formModel.account = undefined
        formModel.captcha = undefined
        if (state.timer) {
          clearInterval(state.timer)
          state.timer = null
          state.captchaText = undefined
          state.captcha = undefined
        }
      } else {
        formModel.oldPassword = undefined
        formModel.newPassword = undefined
        formModel.againPassword = undefined
      }
      formRef.value.clearValidate()
    }

    const checkPhone = async () => {
      // 修改账号
      if (state.nowCaptcha === '验证码失效，请重新发送') {
        cmsNotice('error', state.nowCaptcha)
        state.loading = false
        formModel.nowCaptcha = undefined
        return
      }
      if (typeof state.nowCaptcha === 'number' && state.nowCaptcha !== ~~formModel.nowCaptcha) {
        cmsNotice('error', '验证码错误')
        state.loading = false
        return
      }
      const res = await checkCurrentPhone({
        note: formModel.nowCaptcha,
        phone: props.mobile
      })
      if (res.success) {
        state.captchaText = '获取验证码'
        formModel.nowCaptcha = undefined
        state.timer && clearInterval(state.timer)
        step.value = 2
      }
      state.loading = false
      // formModel.captcha = undefined
    }
    const editUserAccount = async () => {
      // 修改账号
      if (formModel.account === props.mobile.value) {
        cmsNotice('error', '修改账号不能和原来账号一样')
        state.loading = false
        return
      }
      if (state.captcha === '验证码失效，请重新发送') {
        cmsNotice('error', state.captcha)
        state.loading = false
        formModel.captcha = undefined
        return
      }
      if (typeof state.captcha === 'number' && state.captcha !== ~~formModel.captcha) {
        cmsNotice('error', '验证码错误')
        state.loading = false
        return
      }
      const res = await editAccount({
        note: formModel.captcha,
        phone: formModel.account
      })
      if (res.success) {
        const userInfo = JSON.parse(localStorage.getItem('yda-admin-userInfo'))
        userInfo.mobile = formModel.account
        localStorage.setItem('yda-admin-userInfo', JSON.stringify(userInfo))
        store.commit(`user/${SET_USER_INFO}`, userInfo)
        cmsNotice('success', '修改成功')
        emit('update:visible', false)
        state.timer && clearInterval(state.timer)
        state.captchaText = '获取验证码'
        formModel.account = undefined
        formModel.captcha = undefined
      }
      state.loading = false
      // formModel.captcha = undefined
    }
    const editUserPassword = async () => {
      // 修改密码
      // if (formModel.newPassword === formModel.oldPassword) {
      //   cmsNotice('error', '新密码不能和旧密码一样')
      //   state.loading = false
      //   return
      // }
      const res = await editAccountPassword({
        newPassword: formModel.newPassword,
        oldPassword: formModel.oldPassword
      })
      if (res.success) {
        emit('update:visible', false)
        cmsNotice('success', '修改成功')
        formModel.newPassword = undefined
        formModel.againPassword = undefined
        formModel.oldPassword = undefined
      }
      state.loading = false
    }

    const resetUserPassword = async () => {
      // 第一次重置密码
      const res = await initUserPass({
        newPassword: formModel.newPassword,
        userId: store.state.user.userInfo.userId
      })
      if (res.success) {
        emit('update:visible', false)
        cmsNotice('success', '设置密码成功')
        const userInfo = JSON.parse(localStorage.getItem('yda-admin-userInfo'))
        userInfo.isNotInitPassword = false
        localStorage.setItem('yda-admin-userInfo', JSON.stringify(userInfo))
        store.commit(`user/${SET_USER_INFO}`, userInfo)
        formModel.newPassword = undefined
        formModel.againPassword = undefined
      }
      state.loading = false
    }
    const modalSubmit = () => {
      // const r = /^[^\u4e00-\u9fa5]+$/
      // if(!r.test(formModel.newPassword)){
      //   cmsNotice('error', '新密码不可输入中文')
      //   return
      // }
      formRef.value
        .validate()
        .then(async () => {
          state.loading = true
          if (props.type === 'account') {
            if (step.value === 1) {
              await checkPhone()
            } else {
              await editUserAccount()
            }
          } else if (props.type === 'password') {
            await editUserPassword()
          } else {
            await resetUserPassword()
          }
        })
        .catch(() => {})
    }

    const cancel = () => {
      step.value = 1
      formRef.value.resetFields()
      emit('update:visible', false)
      clearInterval(state.timer)
      state.timer = null
      state.captchaText = '获取验证码'
    }

    const handledChange = e => {
      step.value === 1 ? (formModel.nowCaptcha = ToCDB(e.target.value)) : (formModel.captcha = ToCDB(e.target.value))
    }

    return {
      step,
      ...toRefs(state),
      formRef,
      formModel,
      rules,
      getCurrentCaptcha,
      getCaptcha,
      closeEditModal,
      modalTitle,
      modalSubmit,
      cancel,
      labelCol: { style: { width: '80px' } },
      handledChange
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-modal-body) {
  padding: 24px 24px 8px;
}
:deep(.ant-form-item) {
  margin-bottom: 16px !important;
}
:deep(.ant-btn) {
  border-radius: 2px;
}
.get-captcha {
  width: 120px;
  height: 32px;
  border-radius: 2px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
  text-align: center;
  margin-left: 8px;
}
.pwd-tips {
  width: 320px;
  font-size: 12px;
  line-height: 17px;
  color: #646566;
}
</style>
